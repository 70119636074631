import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { User } from '../../core/models/User';
import { UserService } from '../../core/services/user.service';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class UserResolver  {
  router: any;
  constructor(private authService: AuthService) {}

  resolve(): Observable<User> {
    return this.authService.getCurrentUser$().pipe(
      take(1),
      mergeMap(user => {
        if (user) {
          return of(user);
        } else {
          this.router.navigate(['/login']);

          return EMPTY;
        }
      })
    );
  }
}

@Injectable()
export class GetUserResolver  {
  router: any;
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this.userService.getUser(route.params.id).pipe(
      take(1),
      mergeMap(user => {
        if (user) {
          return of(user);
        } else { // user not found
          return EMPTY;
        }
      })
    );
  }
}
