import {Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../../core/models/User';
import { UserService } from '../../../core/services/user.service';
import clearObject from '../../../core/helpers/clear-object';
import { FormErrorStateMatcher } from '../../../core/helpers/error-state-matcher';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UserHeaderService } from '../../../core/services/user-header.service';

@Component({
  selector: 'app-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss']
})
export class PersonalSettingsComponent implements OnInit {
  public user: User;
  public form: UntypedFormGroup;
  public options: UntypedFormGroup;
  matcher = new FormErrorStateMatcher();
  @ViewChild(MatExpansionPanel, {static: true}) matExpansionPanelElement: MatExpansionPanel;

  constructor(
    private userService: UserService,
    private userHeaderService: UserHeaderService,
    private snackBar: MatSnackBar,
    fb: UntypedFormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    const retrievedUserObjectFromLocalStorage = localStorage.getItem('user');
    const userInLocalStorage = JSON.parse(retrievedUserObjectFromLocalStorage);

    const retrievedUserObjectFromSessionStorage = sessionStorage.getItem('fullCustomerObject');
    const userInSessionStorage = JSON.parse(retrievedUserObjectFromSessionStorage);

    if (userInLocalStorage && userInSessionStorage) {
      this.user = userInLocalStorage;
    }
    else {
      this.user = this.userService.currentUserValue;
    }

    this.form = fb.group({
      name: [this.user.name, Validators.required],
      currentPassword: [''],
      password: [''],
      confirmPassword: [''],
      enableControlNotifications: [this.user.enable_control_notifications, []]
    }, { validator: this.checkPasswords });
  }

  ngOnInit() {
    this.form.controls.currentPassword.valueChanges.subscribe(() => {
      if (this.form.controls.currentPassword.value === '' && !this.form.controls.password.dirty
        && !this.form.controls.confirmPassword.dirty) {
        this.form.controls.currentPassword.setValidators(null);
      } else {
        this.form.controls.currentPassword.setValidators(Validators.required);
      }
      this.form.controls.currentPassword.updateValueAndValidity({ emitEvent: false });
    });

    this.form.controls.password.valueChanges.subscribe(() => {
      if (this.form.controls.password.value === '' && !this.form.controls.currentPassword.dirty
        && !this.form.controls.confirmPassword.dirty) {
        this.form.controls.password.setValidators(null);
      } else {
        this.form.controls.password.setValidators(Validators.required);
      }
      this.form.controls.password.updateValueAndValidity({ emitEvent: false });
    });

    this.form.controls.confirmPassword.valueChanges.subscribe(() => {
      if (this.form.controls.confirmPassword.value === '' && !this.form.controls.password.dirty
        && !this.form.controls.currentPassword.dirty) {
        this.form.controls.confirmPassword.setValidators(null);
      } else {
        this.form.controls.confirmPassword.setValidators(Validators.required);
      }
      this.form.controls.confirmPassword.updateValueAndValidity({ emitEvent: false });
    });
  }

  checkPasswords(group: UntypedFormGroup) {
      const pass = group.controls.password.value;
      const confirmPass = group.controls.confirmPassword.value;
      return pass === confirmPass ? null : { notSame: true };
  }

  public update() {
    const payload = {
      ...clearObject(this.form.value),
      enableControlNotifications: this.form.get('enableControlNotifications').value,
    };
    if (this.user.role.id !== 6) {
      this.userService.updateUser(this.user.id, payload).subscribe((updatedUser) => {
        this.userService.setUser(updatedUser);
        this.snackBar.open('Settings saved', 'Dismiss', {
          duration: 1000, // 1 second
        });
        this.matExpansionPanelElement.close(); // open()
        this.userService.getUpdatedUser(updatedUser);
      });
    }
    else if (this.user.role.id === 6) {
      this.userHeaderService.updateUser(this.user.id, payload).subscribe((updatedUser) => {
        this.userHeaderService.setUser(updatedUser);
        this.snackBar.open('Settings saved', 'Dismiss', {
          duration: 1000, // 1 second
        });
        this.matExpansionPanelElement.close(); // open()
        this.userHeaderService.getUpdatedUser(updatedUser);
      });
    }
  }
}
